import {FeatureTrackingLabels} from "./types";
import {TrackingService} from "./tracking_service";

export class PromoTopicPromo {

    private readonly _featureOrder: number;
    private readonly _item: HTMLLinkElement;
    private readonly featureTrackingTileLabels: FeatureTrackingLabels;
    private trackingService: TrackingService
    private readonly featureIndex: number;

    constructor(private container: Element) {
        this._featureOrder = this.getFeatureOrder();
        this._item = <HTMLLinkElement>container.querySelector('.promo_topicpromo__card')!;

        if (!this._item.classList.contains('promo_topicpromo--ignore')) {
            this.featureTrackingTileLabels = JSON.parse(this._item.getAttribute("data-feature-tracking-tile-labels")!);
            this.trackingService = new TrackingService();
            this.featureIndex = this.getFeatureIndex(this._item);
            this.trackView();
            this._item.addEventListener("click", (e: MouseEvent) => this.trackClick(e), false);
            this.registerVisibleTrackObserver();
        }
    }

    trackClick(e: MouseEvent): void {
        if (e.target instanceof Element) {
            this.trackingService.sendClickTrackingEvent(this._featureOrder, this.featureIndex, this.featureTrackingTileLabels, e.target.getAttribute('data-combo-type'));
        }
    }

    trackView(): void {
        this.trackingService.sendViewTrackingEvent(this._featureOrder, this.featureIndex, this.featureTrackingTileLabels);
    }

    registerVisibleTrackObserver(): void {
        /*                                     */
        const observer = new IntersectionObserver((entries) => {
            const intersectingEntries = entries.filter((entry) => entry.isIntersecting);
            if (intersectingEntries.length > 0) {
                this.trackingService.sendMiniScrollAction(this.featureIndex);
                observer.unobserve(this.container);
            }
        }, {});

        observer.observe(this.container);
    }

    private getFeatureOrder(): number {
        const order = this.container.closest('[data-feature-order]')?.getAttribute('data-feature-order');
        if (order != null) {
            return parseInt(order, 10);
        }
        return 3; /*                                                    */
    }

    private getFeatureIndex(item: Element): number {
        const index = item.getAttribute("data-feature-index");
        if (index != null) {
            return parseInt(index, 10);
        }
        return 1; /*                                                    */
    }

    /*            */
    get featureOrder(): number {
        return this._featureOrder;
    }

    /*            */
    get item(): HTMLLinkElement {
        return this._item;
    }
}
